import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import FiltersModal from "../../components/FiltersModal";
import { getApi } from "../../Api/api";
import InfintiteLoader from "../../components/InfiniteLoader";
import HomeSkeltonLoader from "../../components/HomeSkeltonLoader";

const Home = () => {
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 36;

  const fetchData = async (resetData = false) => {
    let url;
    setLoading(true);
    try {
      if (Object.keys(filters).length > 0) {
        const query = {};
        Object.keys(filters).forEach((key) => {
          if (
            filters[key] !== undefined &&
            filters[key] !== null &&
            filters[key] !== ""
          ) {
            query[key] = filters[key];
          }
        });
        query.page = currentPage;
        query.limit = limit;

        const queryString = new URLSearchParams(query).toString();
        url = `address/get-filtered-address?${queryString}`;
      } else {
        url = `address/get-address?page=${currentPage}&limit=${limit}`;
      }
      const response = await getApi(url);
      if (response?.data?.length > 0) {
        setHasMore(true);
        setDisplayData((prevData) =>
          resetData ? response.data : [...prevData, ...response.data]
        );
        setLoading(false);
      } else {
        setHasMore(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleApplyFilters = () => {
    setDisplayData([]);
    setCurrentPage(1);
    setModalOpen(false);
    fetchData(true);
  };

  const handleResetFilters = () => {
    window.location.reload();
  };

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (hasMore) {
        fetchMoreData();
      }
    }
  };

  const getUnique = (data) => {
    const uniqueData = Array.from(
      new Map(data?.map((item) => [item?.name, item])).values()
    );
    return uniqueData;
  };

  return (
    <div className="flex">
      {loading && currentPage === 1 ? (
        <HomeSkeltonLoader />
      ) : (
        <div className="flex-1 mr-8">
          <div className="flex flex-row sm:flex-row justify-between items-center mb-4 mt-16 fixed w-full bg-white mr-64 py-2">
            <h1 className="text-2xl font-bold mb-4 sm:mb-0 pl-10">
              Properties
            </h1>
            <div className="flex flex-col sm:flex-row mr-10">
              <button
                onClick={() => setModalOpen(!isModalOpen)}
                className="bg-blue-500 text-white p-2 sm:p-2 px-4 sm:px-6 rounded-md font-bold mb-2 sm:mb-0 sm:mr-2"
              >
                Filters
              </button>
              <button
                onClick={handleResetFilters}
                className="bg-gray-500 text-white p-2 sm:p-1 px-4 sm:px-6 rounded-md font-bold"
              >
                Reset Filters
              </button>
            </div>
          </div>
          {getUnique(displayData)?.length > 0 ? (
            <div className="pl-12 mt-32">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 max-xl:grid-cols-2 gap-12 max-lg:grid-cols-1">
                {getUnique(displayData)?.map((property, index) => (
                  <Card
                    key={index}
                    title={property?.name}
                    price={property?.price}
                    location={property?.address?.streetAddress}
                    beds={property?.beds}
                    baths={property?.baths}
                    sqft={property?.sqft}
                    imgUrls={property?.imgUrls}
                    propertyUrls={property?.propertyUrls}
                    zipcode={property?.address?.postalCode}
                    lotArea={property?.webAddressData?.lotArea}
                    FARs={property?.zolaData?.FARs}
                    FAs={property?.zolaData?.FAs}
                    maxApartments={property?.zolaData?.maxApartmentsAllowed}
                    zoningDistricts={property?.webAddressData?.zoningDistricts}
                    listingUpdated={property?.listingUpdated}
                    tipValues = {property?.tipValues}
                  />
                ))}
              </div>
              {hasMore && getUnique(displayData).length >= 3 && (
                <div className="mt-6">
                  <InfintiteLoader />
                </div>
              )}
            </div>
          ) : displayData?.length === 0 ? (
            <>
              <div className="flex flex-col items-center justify-center h-full mt-56">
                <h2 className="text-3xl font-bold text-gray-700 mb-4">
                  Sorry, nothing found
                </h2>
                <p className="text-gray-500">
                  Try adjusting your filters or search criteria.
                </p>
              </div>
            </>
          ) : null}
        </div>
      )}
      <FiltersModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onFilterChange={handleFilterChange}
        applyFilters={handleApplyFilters}
        filters={filters}
      />
    </div>
  );
};

export default Home;
