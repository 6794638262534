import { SquareX } from 'lucide-react';
import React, { useState, useEffect } from 'react';

const FiltersModal = ({ isOpen, onClose, onFilterChange, applyFilters, filters }) => {
  const [localFilters, setLocalFilters] = useState(filters);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleInputChange = (filterName, value) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    onFilterChange(filterName, value);
  };

  if (!isOpen) return null;

  const renderInputField = (id, label, type, value, onChange, placeholder = '', step = '1', min = '0') => (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-gray-800 text-sm">{label}</label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        step={step}
        min={min}
        value={value}
        onChange={onChange}
        className="w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );

  const renderRangeInputField = (id, label, minValue, maxValue, onMinChange, onMaxChange, placeholderMin = '', placeholderMax = '', step = '1', min = '0') => (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-gray-800 text-sm">{label}</label>
      <div className="flex justify-between items-center">
        <input
          type="number"
          placeholder={placeholderMin}
          step={step}
          min={min}
          value={minValue}
          onChange={onMinChange}
          className="w-24 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
        />
        <span className="text-sm text-gray-500">to</span>
        <input
          type="number"
          placeholder={placeholderMax}
          step={step}
          min={min}
          value={maxValue}
          onChange={onMaxChange}
          className="w-24 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
        />
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg">
        <div className="bg-blue-500 text-white py-3 px-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-lg font-semibold">Filters</h2>
          <SquareX className='hover:cursor-pointer' onClick={onClose} />
        </div>
        <div className="space-y-4 p-4">
          {renderRangeInputField(
            'max-apartments',
            'Max Apartments Allowed',
            localFilters.max_apartments_min,
            localFilters.max_apartments_max,
            (e) => handleInputChange('max_apartments_min', parseInt(e.target.value) ),
            (e) => handleInputChange('max_apartments_max', parseInt(e.target.value)),
            'Min apartments',
            'Max apartments'
          )}
          {renderInputField('residential-fa', 'Residential FA', 'number', localFilters.residential_fa, (e) => handleInputChange('residential_fa', parseFloat(e.target.value) ), 'Residential FA', '0.01')}
          
          {renderRangeInputField(
            'price-range',
            'Price Range',
            localFilters.price_min,
            localFilters.price_max,
            (e) => handleInputChange('price_min', e.target.value),
            (e) => handleInputChange('price_max', e.target.value),
            'Min Price',
            'Max Price'
          )}

          {renderRangeInputField(
            'lot-area',
            'Lot Area',
            localFilters.lot_area_min,
            localFilters.lot_area_max,
            (e) => handleInputChange('lot_area_min', parseInt(e.target.value) ),
            (e) => handleInputChange('lot_area_max', parseInt(e.target.value) ),
            'Min area (sqft)',
            'Max area (sqft)'
          )}
          {renderInputField('zip-code', 'Zip Code', 'text', localFilters.zip_code, (e) => handleInputChange('zip_code', e.target.value), 'Enter zip code')}
          {renderInputField('zoning-district', 'Zoning District', 'text', localFilters.zoning_district, (e) => handleInputChange('zoning_district', e.target.value), 'Enter zoning district')}
          
          <div className="space-y-2">
            <label htmlFor="listing-updates" className="block text-gray-800">Listing Updates</label>
            <div className="flex justify-between items-center">
              <input
                type="date"
                value={localFilters.listing_updates_min}
                onChange={(e) => handleInputChange('listing_updates_min', e.target.value)}
                className="w-24 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
              />
              <span className="text-sm text-gray-500">to</span>
              <input
                type="date"
                value={localFilters.listing_updates_max}
                onChange={(e) => handleInputChange('listing_updates_max', e.target.value)}
                className="w-24 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
              />
            </div>
          </div>

          <div className="pt-4">
            <button
              onClick={() => applyFilters(localFilters)}
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 transition-all"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;