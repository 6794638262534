import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    fontSize: "0.875rem",
    color: "#333",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
});

const ImageSection = ({ imgUrls }) => {
  const hasValidImage = imgUrls && imgUrls.length > 0 && imgUrls[0];

  return (
    <div className="flex h-64">
      <div className="w-full border-r border-gray-200">
        {hasValidImage ? (
          <img
            src={imgUrls[0]}
            alt="Main property view"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <span className="text-gray-500">No image available</span>
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderSection = ({ name, zipcode, price }) => (
  <div className="flex justify-between items-center mb-4">
    <div className="flex flex-row items-center justify-between w-full">
      <h2 className="text-base sm:text-lg font-bold text-gray-800 my-2">
        {name} {zipcode}
      </h2>
      <span className="text-base sm:text-lg text-black">{price}</span>
    </div>
  </div>
);

const InfoSection = ({
  lotArea,
  zoningDistricts,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  tip1,
  tip2,
  tip2_5,
  linktip2_5,
  linktip2,
}) => {
  const formattedZoningDistricts = Array.isArray(zoningDistricts)
    ? zoningDistricts.join(", ")
    : zoningDistricts;

  const uniqueFARs = FARs && FARs.length > 0 ? [...new Set(FARs)].join(", ") : "TBD";
  const uniqueFAs = FAs && FAs.length > 0 ? [...new Set(FAs)].join(", ") : "TBD";
  const uniqueMaxApartments = maxApartments && maxApartments.length > 0 ? [...new Set(maxApartments)] : ["TBD"];

  return (
    <div className="flex flex-wrap items-center gap-4">
      <div className="flex items-center gap-2">
        <span className="text-gray-600 text-sm sm:text-md">
          Residential FA:
        </span>
        <div className="flex gap-2">
          {uniqueFAs.split(", ").map((value, index) =>
            value === "TBD" ? (
              <span key={index} className="font-bold text-sm sm:text-sm">
                {value}
              </span>
            ) : (
              <span key={index} className="flex items-center gap-1">
                <span className="font-bold text-sm sm:text-md text-black">
                  {value}
                </span>
                <CustomWidthTooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {tip2}
                      {linktip2 && (
                        <a
                          href={linktip2}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 font-bold text-sm mt-2 block"
                        >
                          Know More
                        </a>
                      )}
                    </span>
                  }
                >
                  <span className="cursor-pointer text-blue-500 text-xs">(i)</span>
                </CustomWidthTooltip>
              </span>
            )
          )}
        </div>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Lot Area:</p>
        <p className="font-bold text-sm sm:text-md">{lotArea || "--"}</p>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Max Apartments:</p>
        <div className="flex gap-2">
          {uniqueMaxApartments?.map((value, index) =>
            value === "TBD" ? (
              <span key={index} className="font-bold text-sm sm:text-sm">
                {value}
              </span>
            ) : (
              <span key={index} className="flex items-center gap-1">
                <span className="font-bold text-sm sm:text-md text-black">
                  {value}
                </span>
                <CustomWidthTooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {tip2_5}
                      {linktip2_5 && (
                        <a
                          href={linktip2_5}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 font-bold text-sm mt-2 block"
                        >
                          Know More
                        </a>
                      )}
                    </span>
                  }
                >
                  <span className="cursor-pointer text-blue-500 text-xs">(i)</span>
                </CustomWidthTooltip>
              </span>
            )
          )}
        </div>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Zoning Districts:</p>
        <p className="font-bold text-sm sm:text-md">
          {formattedZoningDistricts || "--"}
        </p>
      </div>
      <div className="hidden">
        <span className="text-gray-600 text-sm sm:text-md">
          Listing Updated:
        </span>
        <span className="font-bold text-sm sm:text-md">{listingUpdated}</span>
      </div>
    </div>
  );
};

const ButtonSection = ({ handleClick }) => (
  <div className="flex items-center justify-center mt-4">
    <button
      onClick={handleClick}
      className="bg-blue-500 w-full text-white rounded-md font-semibold hover:bg-blue-700 transition-colors px-3 py-2 text-sm md:text-base"
    >
      View Property
    </button>
  </div>
);

const Card = ({
  title,
  price,
  location,
  beds,
  baths,
  sqft,
  imgUrls,
  propertyUrls,
  zipcode,
  lotArea,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  zoningDistricts,
  tipValues,
}) => {
  const handleClick = () => {
    if (propertyUrls && propertyUrls.length > 0) {
      window.open(propertyUrls[0], "_blank");
    }
  };

  const { tip1, tip2, tip2_5value, linktip2_5, linktip2 } = tipValues;
  const name = title?.replace(/\s\d{5}$/, "");
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full flex flex-col">
      <ImageSection imgUrls={imgUrls} />
      <div className="p-4 md:p-6 flex flex-col">
        <HeaderSection name={name} zipcode={zipcode} price={price} />
        <InfoSection
          lotArea={lotArea}
          zoningDistricts={zoningDistricts}
          FARs={FARs}
          FAs={FAs}
          maxApartments={maxApartments}
          listingUpdated={listingUpdated}
          tip1={tip1}
          tip2={tip2}
          tip2_5={tip2_5value}
          linktip2={linktip2}
          linktip2_5={linktip2_5}
        />
        <ButtonSection handleClick={handleClick} />
      </div>
    </div>
  );
};

export default Card;